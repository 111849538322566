@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
@font-face {
  font-family: "Airfool";
  src: url("../fonts/Airfool.eot");
  src: url("../fonts/Airfool.eot") format("embedded-opentype"), url("../fonts/Airfool.otf") format("otf"), url("../fonts/Airfool.svg") format("svg"), url("../fonts/Airfool.ttf") format("truetype"), url("../fonts/Airfool.woff") format("woff"), url("../fonts/Airfool.woff2") format("woff2");
}
.programs_block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100svh;
  min-height: 100vh;
  width: 100vw;
  background-color: #BEE2EE;
}
.programs_block .cloud {
  position: absolute;
}
.programs_block .cloud__1 {
  top: 10.3vh;
  left: 12.3vw;
  width: 15.9vw;
}
.programs_block .cloud__2 {
  bottom: 17.8vh;
  left: 2.66vw;
  width: 25.5vw;
}
.programs_block .cloud__3 {
  bottom: 4.4vh;
  left: 50.5vw;
  width: 9.6vw;
}
.programs_block .cloud__4 {
  bottom: 26.1vh;
  right: 0;
  width: 18vw;
}
.programs_block .small_cloud {
  position: absolute;
}
.programs_block .small_cloud__1 {
  top: 16.4vh;
  left: 37.2vw;
  width: 3.5vw;
}
.programs_block .small_cloud__2 {
  top: 20.7vh;
  left: 34.2vw;
  width: 2.4vw;
}
.programs_block .small_cloud__3 {
  bottom: 34.6vh;
  right: 37.5vw;
  width: 3.5vw;
}
.programs_block .small_cloud__4 {
  bottom: 31.9vh;
  right: 35.9vw;
  width: 2.4vw;
}
.programs_block .plane {
  position: absolute;
  top: 5vh;
  right: 14.5vw;
  width: 14.2vw;
}
.programs_block .planet_box {
  position: relative;
  margin-top: 0;
  width: 38.5vw;
  height: 39.32005vw;
  align-self: center;
  transition: all 2s ease-in-out;
}
.programs_block .planet_box.active {
  width: 55.8vw;
  height: 56.98854vw;
  margin-top: 33vh;
}
.programs_block .planet_box .planet {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 2s ease-in-out;
  z-index: 2;
}
.programs_block .planet_box .planet.turn {
  transform: rotate(20deg);
  transition: transform 2s;
}
.programs_block .planet_box .axis_img {
  position: absolute;
  opacity: 1;
  top: 29%;
  bottom: 0;
  left: 19.3%;
  right: 0;
  width: 68%;
  z-index: 3;
  transition: opacity 1.5s ease;
}
.programs_block .planet_box .axis_img.hidden {
  opacity: 0;
  z-index: 4;
}
.programs_block .changing_block {
  position: absolute;
  z-index: 6;
  width: 82.5vw;
  height: clamp(140px, 23.125vh, 300px);
  background-color: rgba(255, 255, 255, 0.83);
  border: 0.5px solid rgba(29, 53, 87, 0.83);
  border-radius: 30px;
  color: #465299;
  cursor: pointer;
  transition: height 2s ease-in-out;
}
.programs_block .changing_block.animated {
  animation: pulse 2s 2;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.programs_block .changing_block .introductory_block {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5.7vh;
  gap: 1.2vh;
  transition: display 1s ease-in-out;
}
.programs_block .changing_block .introductory_block .title {
  font-family: "Airfool";
  color: #465299;
  font-size: clamp(20px, 3.3vw, 64px);
  line-height: clamp(12px, 4.125vh, 45px);
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}
.programs_block .changing_block .introductory_block .text {
  font-family: "Montserrat";
  font-size: clamp(20px, 1.35vw, 54px);
}
.programs_block .changing_block .introductory_block.hide_block {
  display: none;
}
.programs_block .changing_block .programm_block {
  display: flex;
  opacity: 0;
  height: 100%;
  visibility: hidden;
  flex-direction: row;
  padding: 2.6vh 3.75vw;
  gap: 1.5vw;
  transition: opacity 1s ease-in-out 1.7s;
}
.programs_block .changing_block .programm_block .title_block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26.2%;
}
.programs_block .changing_block .programm_block .title_block .title {
  font-family: "Airfool";
  color: #465299;
  text-align: center;
  font-size: clamp(12px, 1.8vw, 32px);
  line-height: clamp(12px, 4vh, 40px);
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}
.programs_block .changing_block .programm_block .info_block {
  display: flex;
  flex-direction: column;
  width: 62.4%;
  padding-right: 1.56vw;
  padding-bottom: 4.1vh;
  font-family: "Montserrat";
  color: #1D1D1B;
}
.programs_block .changing_block .programm_block .info_block .first_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3.2vh 0;
  font-weight: 500;
  font-size: 0.93vw;
  border-bottom: 1px solid rgba(29, 53, 87, 0.83);
}
.programs_block .changing_block .programm_block .info_block .first_div div {
  display: flex;
  flex-direction: row;
  align-self: center;
}
.programs_block .changing_block .programm_block .info_block .first_div div .bottom_right {
  padding-right: 10px;
  border-right: 1px solid rgba(29, 53, 87, 0.83);
}
.programs_block .changing_block .programm_block .info_block .first_div div .padding_left {
  padding-left: 10px;
}
.programs_block .changing_block .programm_block .info_block .first_div button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #B24141;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.programs_block .changing_block .programm_block .info_block .first_div button img {
  width: 10px;
}
.programs_block .changing_block .programm_block .info_block .first_div button:hover {
  background-color: rgba(209, 37, 37, 0.9921568627);
}
.programs_block .changing_block .programm_block .info_block div:nth-child(2) {
  display: flex;
  flex-direction: column;
  padding: 4.4vh 0;
  gap: 2.4vh;
  font-weight: 400;
  font-size: 0.88vw;
}
.programs_block .changing_block .programm_block .info_block div:nth-child(2) ul {
  list-style: disc;
}
.programs_block .changing_block .programm_block .info_block div:nth-child(2) ul li {
  margin-left: 0.78vw;
  list-style: disc;
  margin-bottom: 1.6vh;
}
.programs_block .changing_block .programm_block .info_block div:nth-child(3) {
  display: flex;
  justify-content: end;
}
.programs_block .changing_block .programm_block .info_block div:nth-child(3) .toggle_btn {
  display: none;
}
.programs_block .changing_block .programm_block .info_block div:nth-child(3) .more_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.4vw;
  height: 3.125vw;
  background-color: #B24141;
  color: #fff;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 0.83vw;
  transition: background-color 0.3s ease;
}
.programs_block .changing_block .programm_block .info_block div:nth-child(3) .more_btn a {
  color: #fff;
}
.programs_block .changing_block .programm_block .info_block div:nth-child(3) .more_btn:hover {
  background-color: rgba(209, 37, 37, 0.9921568627);
}
.programs_block .changing_block .programm_block.show_block {
  visibility: visible;
  opacity: 1;
}
.programs_block .changing_block.bigger {
  height: clamp(300px, 49vh, 800px);
}
.programs_block .changing_block.auto_height {
  height: auto;
}

@media screen and (orientation: portrait) and (min-width: 435px) {
  .programs_block {
    min-height: 720px;
  }
}
@media screen and (orientation: portrait) and (max-width: 435px) {
  .programs_block {
    min-height: 64svh;
  }
}
@media screen and (orientation: portrait) {
  .programs_block {
    height: 64vh;
  }
  .programs_block .cloud__1 {
    top: 2.4vh;
    left: 22vw;
    width: clamp(50px, 24.4vw, 200px);
  }
  .programs_block .cloud__2 {
    bottom: 6.65vh;
    left: 1.86vw;
    width: clamp(80px, 26vw, 216px);
  }
  .programs_block .cloud__3 {
    bottom: 1.7vh;
    left: 51vw;
    width: 9.8vw;
  }
  .programs_block .cloud__4 {
    bottom: 6.3vh;
    width: clamp(80px, 25.6vw, 212px);
  }
  .programs_block .small_cloud__1 {
    top: 16.3vh;
    left: 18.6vw;
    width: clamp(10px, 8.84vw, 60px);
  }
  .programs_block .small_cloud__2 {
    top: 18.9vh;
    left: 12vw;
    width: clamp(10px, 6vw, 40px);
  }
  .programs_block .small_cloud__3 {
    bottom: 22.1vh;
    right: 23.4vw;
    width: clamp(10px, 8.8vw, 60px);
  }
  .programs_block .small_cloud__4 {
    bottom: 19.6vh;
    right: 17.5vw;
    width: clamp(10px, 6vw, 40px);
  }
  .programs_block .plane {
    top: 3vh;
    right: 13vw;
    width: clamp(80px, 23.3vw, 200px);
  }
  .programs_block .planet_box {
    width: clamp(200px, 73vw, 600px);
    height: 74.5549vw;
  }
  .programs_block .planet_box.active {
    width: 87vw;
    height: 88.8531vw;
    margin-top: 33vh;
  }
  .programs_block .changing_block {
    width: 88.8vw;
    height: clamp(80px, 9.87vh, 150px);
    border-radius: 20px;
  }
  .programs_block .changing_block .introductory_block {
    height: 100%;
    padding: 5.7vh;
    gap: 1.2vh;
  }
  .programs_block .changing_block .introductory_block .title {
    font-size: clamp(20px, 5.58vw, 44px);
    line-height: clamp(2px, 0.5vh, 12px);
    white-space: nowrap;
  }
  .programs_block .changing_block .introductory_block .text {
    font-size: clamp(10px, 2.56vw, 31px);
    text-align: center;
  }
  .programs_block .changing_block .programm_block {
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem;
    gap: 0;
  }
  .programs_block .changing_block .programm_block .title_block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .programs_block .changing_block .programm_block .title_block .title {
    padding-top: 0.5em;
    font-size: clamp(18px, 4.65vw, 34px);
    line-height: clamp(5px, 1vh, 12px);
  }
  .programs_block .changing_block .programm_block .info_block {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0;
    padding-bottom: 0;
  }
  .programs_block .changing_block .programm_block .info_block .first_div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.5rem;
    font-size: clamp(12px, 3.25vw, 24px);
  }
  .programs_block .changing_block .programm_block .info_block .first_div div .bottom_right {
    padding-right: 0.8rem;
    border-right: 1px solid rgba(29, 53, 87, 0.83);
  }
  .programs_block .changing_block .programm_block .info_block .first_div div .padding_left {
    padding-left: 0.8rem;
  }
  .programs_block .changing_block .programm_block .info_block .first_div button {
    display: none;
  }
  .programs_block .changing_block .programm_block .info_block div:nth-child(2) {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0.8rem;
    gap: 1rem;
    font-weight: 400;
    font-size: clamp(12px, 3.5vw, 24px);
  }
  .programs_block .changing_block .programm_block .info_block div:nth-child(2) ul li {
    margin-left: 3.78vw;
    margin-bottom: 0.2rem;
  }
  .programs_block .changing_block .programm_block .info_block div:nth-child(3) {
    flex-direction: row;
    justify-content: space-around;
  }
  .programs_block .changing_block .programm_block .info_block div:nth-child(3) .more_btn {
    width: 34.4vw;
    height: 9vw;
    font-size: clamp(13px, 3.1vw, 22px);
  }
  .programs_block .changing_block .programm_block .info_block div:nth-child(3) .toggle_btn {
    display: block;
    border-radius: 50%;
    background-color: #B24141;
    width: 9vw;
    height: 9vw;
    transition: background-color 0.3s ease;
  }
  .programs_block .changing_block .programm_block .info_block div:nth-child(3) .toggle_btn:hover {
    background-color: rgba(209, 37, 37, 0.9921568627);
  }
  .programs_block .changing_block .programm_block .info_block div:nth-child(3) .toggle_btn img {
    width: 5vw;
    height: 5vw;
  }
  .programs_block .changing_block .programm_block.show_block {
    visibility: visible;
    opacity: 1;
  }
  .programs_block .changing_block.bigger {
    height: clamp(400px, 70%, 700px);
  }
  .programs_block .changing_block.auto_height {
    height: auto;
  }
}/*# sourceMappingURL=train_program.css.map */