@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

@font-face {
  font-family: 'Peace Sans';
  src: url('../fonts/Peace\ Sans.eot');
  src: url('../fonts/Peace\ Sans.eot') format('embedded-opentype'),
  url('../fonts/Peace\ Sans.otf') format('otf'),
  url('../fonts/Peace\ Sans.svg') format('svg'),
  url('../fonts/Peace\ Sans.ttf') format('truetype'),
  url('../fonts/Peace\ Sans.woff') format('woff'),
  url('../fonts/Peace\ Sans.woff2') format('woff2');
}

@font-face {
  font-family: 'Airfool';
  src: url('../fonts/Airfool.eot');
  src: url('../fonts/Airfool.eot') format('embedded-opentype'),
       url('../fonts/Airfool.otf') format('otf'),
       url('../fonts/Airfool.svg') format('svg'),
       url('../fonts/Airfool.ttf') format('truetype'),
       url('../fonts/Airfool.woff') format('woff'),
       url('../fonts/Airfool.woff2') format('woff2');
}

@font-face {
	font-family: 'Cruinn';
	src: url('../fonts/curinn_reg/Cruinn-Light.woff') format('woff'), 
       url('../fonts/curinn_reg/Cruinn-Light.woff2') format('woff2'),
       url('../fonts/curinn_med/Cruinn-Medium.woff') format('woff'),
       url('../fonts/curinn_med/Cruinn-Medium.woff2') format('woff2');
}

.form_block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100svh;
  min-height: 100vh;
  width: 100vw;
  background-color: #63C6F8;

  .white_block {
    display: flex;
    flex-direction: row;
  
    width: 57vw;
    min-width: 620px;
    height: clamp(400px, 57.7vh, 800px);
    // min-height: 350px;

    background-color: #fff;
    border-radius: 30px;

    .form {
      display: grid;
      // grid-template-rows: 18% 57% 10% 15%;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      // gap: clamp(10px, 1vw, 20px);
      width: 100%;
      min-width: 360px;
      height: 100%;
      // padding: 23px 47px;
      // padding: clamp(15px, 1.2vw, 25px) clamp(20px, 4.9vw, 30px);
      padding: clamp(10px, 2vh, 22px) clamp(15px, 2vw, 40px);

      .title {
        padding: clamp(10px, 2vh, 20px) 0;
        font-family: 'Airfool';
        text-align: center;
        color: #465299;
        font-size: clamp(30px, 2.6vw, 72px);
        // line-height: clamp(34px, 3.9vw, 74px);
        
        text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
      }

      .inputs_block {
        width: 100%;
        height: 100%;

        form {
          display: flex;
          flex-direction: column;
          gap: clamp(8px, 2vh, 30px);
        }
        
        .input {
          width: 100%;
          height: clamp(40px, 5.2vh, 70px);
          border: 1px solid rgba(29, 53, 87, 0.83); 
          border-radius: 30px;
          // padding: clamp(15px, 2vw, 20px);
          padding: 15px clamp(15px, 2vw, 24px);

          font-family: 'Cruinn';
          font-weight: 500;
          font-size: clamp(11px, 0.83vw, 20px);
          &::placeholder {
            font-family: 'Cruinn';
            color: #1D3557;
            font-weight: 500;
            // font-size: clamp(9px, 0.83vw, 20px);
          }
        }

        .react-tel-input, .flag-dropdown.open, .selected-flag {
          background-color: transparent;
        }
        .react-tel-input {
          background-color: transparent;
        }

        .form-control {
          width: 100%;
          height: clamp(40px, 5.2vh, 70px);
          border: 1px solid rgba(29, 53, 87, 0.83); 
          border-radius: 30px;
          padding-left: 58px;

          font-family: 'Cruinn';
          font-weight: 500;
          font-size: clamp(11px, 0.83vw, 20px);
        }

        .flag-dropdown {
         border: none;
         background-color: transparent;
         &.open {
          background-color: transparent;
         } 
         .selected_flag {
          background-color: transparent;
         }

         .country-list {
          border-radius: 10px;
          font-family: 'Cruinn';
          font-weight: 500;
         }
        }

        .flag-dropdown {
          padding-left: clamp(7px, 1.2vw, 16px);
        }

        .border_error_phone {
          .form-control {
            border: 1px solid rgba(168, 30, 15, 0.83) !important; 
          }
        }
        .border_error {
          border: 1px solid rgba(168, 30, 15, 0.83) !important; 
        }
      }

      .consent {
        display: flex;
        flex-direction: row;
        justify-content: start;
        gap: 10px;
        width: 100%;
        // padding: 0 clamp(10px, 2vw, 20px);
        input {
          width: clamp(13px, 0.68vw, 20px);
        }
        label {
          align-self: center;
          font-family: 'Cruinn';
          color: #1D3557;
          font-weight: 300;
          font-size: clamp(10px, 0.68vw, 24px);
        }

      }

      .submit_btn {
        display: flex;
        justify-content: center;

        .button {
          font-family: 'Airfool';
          text-align: center;
          
          &.response_massage {
            opacity: 0;
            height: clamp(20px, 6.2vh, 68px);
            width:  0;
            background-color: #fff;
            border-radius: 50%;
            transition: all 0.3s ease-out;

            &.active_massage {
              opacity: 1;
              width:  clamp(20px, 6.2vh, 68px);
            }

            &.success {
              color: #01b2a9;
              border: 2px solid #01b2a9;
            }
            &.fail {
              color: #E03635;
              border: 2px solid  #E03635;
            }
            

          }
          &.submit {
            width: auto;
            height: clamp(40px, 6.2vh, 68px);
            padding: 0 clamp(10px, 1vw, 25px);
            border-radius: 30px;
            color: #fff;
            cursor: pointer;
            font-size: clamp(16px, 1.25vw, 36px);
            text-shadow: -1px 0 #582523, 0 1px #582523, 1px 0 #582523, 0 -1px #582523;
            &.locked {
              background-color: #de535393;
            }
            &.unlocked {
              background-color: #E03635;
            }
          }
        }

        
      }

    }

    .decorate_block {
      width: 100%;
      min-width: 360px;
      height: 100%;

      background-color: #4079BE;
      border-radius: 30px;
      background-image: url('../img/form_block/boy_and_study.svg'); 
      background-position: center; 
      background-repeat: no-repeat; 
      background-size: cover;
    }
  }

  // @media screen and (max-width: 1100px) {
  @media screen and (orientation: portrait) {
    .white_block {
      flex-direction: column;

      width: 49.7vw;
      height: 80vh;
      min-width: 300px;  

        .form {
          // min-height: 380px;
          height: fit-content;
          min-width: 300px;

          .title {
            font-size: clamp(24px, 2.6vw, 72px);
          }
          .inputs_block {
            form {
              gap: clamp(8px, 1.6vh, 20px);
            }
            .input {
              height: clamp(25px, 5.2vh, 50px);
              // font-size: clamp(11px, 0.83vw, 20px);
            }

            .form-control {
              height: clamp(30px, 5.2vh, 50px);
              padding-left: 48px;
            }
          }

          .consent {
            label {
              font-size: clamp(8px, 0.68vw, 24px);
            }
          }
          .submit_btn {
            .button {
              &.response_massage {
                height: clamp(20px, 4.2vh, 68px);
                &.active_massage {
                  width:  clamp(20px, 4.2vh, 68px);
                }
    
                &.success {
                  color: #01b2a9;
                  border: 2px solid #01b2a9;
                }
                &.fail {
                  color: #E03635;
                  border: 2px solid  #E03635;
                }
                
    
              }
              &.submit {
                height: clamp(40px, 4.2vh, 68px);
                padding: 0 clamp(10px, 1vw, 25px);
                font-size: clamp(12px, 2vw, 38px);
              }
            }
    
            
          }
        }

        .decorate_block {
          min-width: 300px;
          min-height: 200px;
          flex-grow: 1;
        }
    }
  }

}