@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
@font-face {
  font-family: "Peace Sans";
  src: url("../fonts/Peace Sans.eot");
  src: url("../fonts/Peace Sans.eot") format("embedded-opentype"), url("../fonts/Peace Sans.otf") format("otf"), url("../fonts/Peace Sans.svg") format("svg"), url("../fonts/Peace Sans.ttf") format("truetype"), url("../fonts/Peace Sans.woff") format("woff"), url("../fonts/Peace Sans.woff2") format("woff2");
}
@font-face {
  font-family: "Airfool";
  src: url("../fonts/Airfool.eot");
  src: url("../fonts/Airfool.eot") format("embedded-opentype"), url("../fonts/Airfool.otf") format("otf"), url("../fonts/Airfool.svg") format("svg"), url("../fonts/Airfool.ttf") format("truetype"), url("../fonts/Airfool.woff") format("woff"), url("../fonts/Airfool.woff2") format("woff2");
}
@font-face {
  font-family: "Cruinn";
  src: url("../fonts/curinn_reg/Cruinn-Light.woff") format("woff"), url("../fonts/curinn_reg/Cruinn-Light.woff2") format("woff2"), url("../fonts/curinn_med/Cruinn-Medium.woff") format("woff"), url("../fonts/curinn_med/Cruinn-Medium.woff2") format("woff2");
}
.form_block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100svh;
  min-height: 100vh;
  width: 100vw;
  background-color: #63C6F8;
}
.form_block .white_block {
  display: flex;
  flex-direction: row;
  width: 57vw;
  min-width: 620px;
  height: clamp(400px, 57.7vh, 800px);
  background-color: #fff;
  border-radius: 30px;
}
.form_block .white_block .form {
  display: grid;
  width: 100%;
  min-width: 360px;
  height: 100%;
  padding: clamp(10px, 2vh, 22px) clamp(15px, 2vw, 40px);
}
.form_block .white_block .form .title {
  padding: clamp(10px, 2vh, 20px) 0;
  font-family: "Airfool";
  text-align: center;
  color: #465299;
  font-size: clamp(30px, 2.6vw, 72px);
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}
.form_block .white_block .form .inputs_block {
  width: 100%;
  height: 100%;
}
.form_block .white_block .form .inputs_block form {
  display: flex;
  flex-direction: column;
  gap: clamp(8px, 2vh, 30px);
}
.form_block .white_block .form .inputs_block .input {
  width: 100%;
  height: clamp(40px, 5.2vh, 70px);
  border: 1px solid rgba(29, 53, 87, 0.83);
  border-radius: 30px;
  padding: 15px clamp(15px, 2vw, 24px);
  font-family: "Cruinn";
  font-weight: 500;
  font-size: clamp(11px, 0.83vw, 20px);
}
.form_block .white_block .form .inputs_block .input::-moz-placeholder {
  font-family: "Cruinn";
  color: #1D3557;
  font-weight: 500;
}
.form_block .white_block .form .inputs_block .input::placeholder {
  font-family: "Cruinn";
  color: #1D3557;
  font-weight: 500;
}
.form_block .white_block .form .inputs_block .react-tel-input, .form_block .white_block .form .inputs_block .flag-dropdown.open, .form_block .white_block .form .inputs_block .selected-flag {
  background-color: transparent;
}
.form_block .white_block .form .inputs_block .react-tel-input {
  background-color: transparent;
}
.form_block .white_block .form .inputs_block .form-control {
  width: 100%;
  height: clamp(40px, 5.2vh, 70px);
  border: 1px solid rgba(29, 53, 87, 0.83);
  border-radius: 30px;
  padding-left: 58px;
  font-family: "Cruinn";
  font-weight: 500;
  font-size: clamp(11px, 0.83vw, 20px);
}
.form_block .white_block .form .inputs_block .flag-dropdown {
  border: none;
  background-color: transparent;
}
.form_block .white_block .form .inputs_block .flag-dropdown.open {
  background-color: transparent;
}
.form_block .white_block .form .inputs_block .flag-dropdown .selected_flag {
  background-color: transparent;
}
.form_block .white_block .form .inputs_block .flag-dropdown .country-list {
  border-radius: 10px;
  font-family: "Cruinn";
  font-weight: 500;
}
.form_block .white_block .form .inputs_block .flag-dropdown {
  padding-left: clamp(7px, 1.2vw, 16px);
}
.form_block .white_block .form .inputs_block .border_error_phone .form-control {
  border: 1px solid rgba(168, 30, 15, 0.83) !important;
}
.form_block .white_block .form .inputs_block .border_error {
  border: 1px solid rgba(168, 30, 15, 0.83) !important;
}
.form_block .white_block .form .consent {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 10px;
  width: 100%;
}
.form_block .white_block .form .consent input {
  width: clamp(13px, 0.68vw, 20px);
}
.form_block .white_block .form .consent label {
  align-self: center;
  font-family: "Cruinn";
  color: #1D3557;
  font-weight: 300;
  font-size: clamp(10px, 0.68vw, 24px);
}
.form_block .white_block .form .submit_btn {
  display: flex;
  justify-content: center;
}
.form_block .white_block .form .submit_btn .button {
  font-family: "Airfool";
  text-align: center;
}
.form_block .white_block .form .submit_btn .button.response_massage {
  opacity: 0;
  height: clamp(20px, 6.2vh, 68px);
  width: 0;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.3s ease-out;
}
.form_block .white_block .form .submit_btn .button.response_massage.active_massage {
  opacity: 1;
  width: clamp(20px, 6.2vh, 68px);
}
.form_block .white_block .form .submit_btn .button.response_massage.success {
  color: #01b2a9;
  border: 2px solid #01b2a9;
}
.form_block .white_block .form .submit_btn .button.response_massage.fail {
  color: #E03635;
  border: 2px solid #E03635;
}
.form_block .white_block .form .submit_btn .button.submit {
  width: auto;
  height: clamp(40px, 6.2vh, 68px);
  padding: 0 clamp(10px, 1vw, 25px);
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  font-size: clamp(16px, 1.25vw, 36px);
  text-shadow: -1px 0 #582523, 0 1px #582523, 1px 0 #582523, 0 -1px #582523;
}
.form_block .white_block .form .submit_btn .button.submit.locked {
  background-color: rgba(222, 83, 83, 0.5764705882);
}
.form_block .white_block .form .submit_btn .button.submit.unlocked {
  background-color: #E03635;
}
.form_block .white_block .decorate_block {
  width: 100%;
  min-width: 360px;
  height: 100%;
  background-color: #4079BE;
  border-radius: 30px;
  background-image: url("../img/form_block/boy_and_study.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (orientation: portrait) {
  .form_block .white_block {
    flex-direction: column;
    width: 49.7vw;
    height: 80vh;
    min-width: 300px;
  }
  .form_block .white_block .form {
    height: -moz-fit-content;
    height: fit-content;
    min-width: 300px;
  }
  .form_block .white_block .form .title {
    font-size: clamp(24px, 2.6vw, 72px);
  }
  .form_block .white_block .form .inputs_block form {
    gap: clamp(8px, 1.6vh, 20px);
  }
  .form_block .white_block .form .inputs_block .input {
    height: clamp(25px, 5.2vh, 50px);
  }
  .form_block .white_block .form .inputs_block .form-control {
    height: clamp(30px, 5.2vh, 50px);
    padding-left: 48px;
  }
  .form_block .white_block .form .consent label {
    font-size: clamp(8px, 0.68vw, 24px);
  }
  .form_block .white_block .form .submit_btn .button.response_massage {
    height: clamp(20px, 4.2vh, 68px);
  }
  .form_block .white_block .form .submit_btn .button.response_massage.active_massage {
    width: clamp(20px, 4.2vh, 68px);
  }
  .form_block .white_block .form .submit_btn .button.response_massage.success {
    color: #01b2a9;
    border: 2px solid #01b2a9;
  }
  .form_block .white_block .form .submit_btn .button.response_massage.fail {
    color: #E03635;
    border: 2px solid #E03635;
  }
  .form_block .white_block .form .submit_btn .button.submit {
    height: clamp(40px, 4.2vh, 68px);
    padding: 0 clamp(10px, 1vw, 25px);
    font-size: clamp(12px, 2vw, 38px);
  }
  .form_block .white_block .decorate_block {
    min-width: 300px;
    min-height: 200px;
    flex-grow: 1;
  }
}/*# sourceMappingURL=form.css.map */