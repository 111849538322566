.main_container {
  // height: 100vh;
  // min-height: 100svh;
  // min-height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  transition: scroll-snap-align 4s ease;
  scroll-behavior: smooth;
  .item_container {
    height: 100%;
    width: 100%;
    scroll-snap-align: center;
    transition: scroll-snap-align 4s ease;
    scroll-behavior: smooth;
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .item_container__last {
    height: 27.5%;
    width: 100%;
    scroll-snap-align: end;
  }
}