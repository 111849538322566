@font-face {
  font-family: "Airfool";
  src: url("../fonts/Airfool.eot");
  src: url("../fonts/Airfool.eot") format("embedded-opentype"), url("../fonts/Airfool.otf") format("otf"), url("../fonts/Airfool.svg") format("svg"), url("../fonts/Airfool.ttf") format("truetype"), url("../fonts/Airfool.woff") format("woff"), url("../fonts/Airfool.woff2") format("woff2");
}
.quote_block {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100svh;
  min-height: 100vh;
  width: 100vw;
  padding: 18.85vh 8.8vw;
  background-image: url("/src/img/quote_block/quote_back.svg");
  background-color: #9EDAEE;
  background-size: cover;
  background-position: center;
}
.quote_block .quote_text {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82.4vw;
  height: 67.5vh;
  padding: 0 3.6vw;
  color: #FFF;
  font-family: "Airfool";
  font-size: clamp(22px, 3.3vw, 94px);
}
.quote_block .quote_text span {
  text-align: center;
  text-shadow: -1px 0 #465299, 0 1px #465299, 1px 0 #465299, 0 -1px #465299;
}
.quote_block .quote_text .cloud_up_left {
  position: absolute;
  top: 0;
  right: 8.2vw;
}
.quote_block .quote_text .cloud_up_left__img {
  height: auto;
  width: 17.8vw;
}
.quote_block .quote_text .cloud_up_right {
  position: absolute;
  top: 15.2vh;
  right: 0;
}
.quote_block .quote_text .cloud_up_right__img {
  height: auto;
  width: 11.35vw;
}
.quote_block .quote_text .cloud_down {
  position: absolute;
  bottom: 0;
  left: 0;
}
.quote_block .quote_text .cloud_down__img {
  height: auto;
  width: 11.35vw;
}
.quote_block .quote_text .plain {
  position: absolute;
  top: -17vh;
  left: 6vw;
}
.quote_block .quote_text .plain__img {
  height: auto;
  width: 33.85vw;
}

@media screen and (orientation: portrait) and (min-width: 435px) {
  .quote_block {
    min-height: 670px;
  }
}
@media screen and (orientation: portrait) and (max-width: 435px) {
  .quote_block {
    min-height: 46svh;
    min-height: 46vh;
  }
}
@media screen and (orientation: portrait) {
  .quote_block {
    height: 46vh;
    padding: 1vw;
  }
  .quote_block .quote_text {
    width: 100%;
    height: 100%;
    font-size: clamp(18px, 4.65vw, 40px);
  }
  .quote_block .quote_text span {
    margin-top: 13vh;
  }
  .quote_block .quote_text .cloud_up_left {
    top: 13.9vh;
    right: 25vw;
  }
  .quote_block .quote_text .cloud_up_left__img {
    width: clamp(60px, 28vw, 250px);
  }
  .quote_block .quote_text .cloud_up_right {
    top: 21.7vh;
    right: 11.6vw;
  }
  .quote_block .quote_text .cloud_up_right__img {
    width: clamp(40px, 16.3vw, 120px);
  }
  .quote_block .quote_text .cloud_down {
    bottom: 3.5vh;
    left: 17vw;
  }
  .quote_block .quote_text .cloud_down__img {
    width: clamp(80px, 32.56vw, 270px);
  }
  .quote_block .quote_text .plain {
    top: 0;
    left: 6vw;
  }
  .quote_block .quote_text .plain__img {
    width: clamp(100px, 46.85vw, 450px);
  }
}/*# sourceMappingURL=quote_block.css.map */