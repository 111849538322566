@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

@font-face {
  font-family: 'Peace Sans';
  src: url('../fonts/Peace\ Sans.eot');
  src: url('../fonts/Peace\ Sans.eot') format('embedded-opentype'),
  url('../fonts/Peace\ Sans.otf') format('otf'),
  url('../fonts/Peace\ Sans.svg') format('svg'),
  url('../fonts/Peace\ Sans.ttf') format('truetype'),
  url('../fonts/Peace\ Sans.woff') format('woff'),
  url('../fonts/Peace\ Sans.woff2') format('woff2');
}

@font-face {
  font-family: 'Airfool';
  src: url('../fonts/Airfool.eot');
  src: url('../fonts/Airfool.eot') format('embedded-opentype'),
       url('../fonts/Airfool.otf') format('otf'),
       url('../fonts/Airfool.svg') format('svg'),
       url('../fonts/Airfool.ttf') format('truetype'),
       url('../fonts/Airfool.woff') format('woff'),
       url('../fonts/Airfool.woff2') format('woff2');
}

.questions_block {
  display: flex;
  flex-direction: column;

  min-height: 100svh;
  min-height: 100vh;
  width: 100vw;
  background-color: #9EDAEE;
  background-image: url('/src/img/questions_block/back_img.svg');
  background-size: cover;
  background-position: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 10.4vh;
      margin-bottom: 4.6vh;
      padding: 0 4.69vw;
      width: 100%;
      color: #fff;

      span {
        display: block;
        align-self: center;
        font-family: 'Airfool';
        text-shadow: -1px 0 #465299, 0 1px #465299, 1px 0 #465299, 0 -1px #465299;
        font-size: clamp(32px, 3.75vw, 72px);
        line-height: clamp(34px, 3.9vw, 74px);
      }
    }

    .accordion {
      width: clamp(300px, 57.3vw, 1400px);
      background-color: rgba(255, 255, 255, 0.83);
      border-radius: 30px;
      padding: 17px 20px;

      .item {

        .title {
          display: grid;
          grid-template-columns: 4.8fr 1fr;
          align-items: center;
          gap: clamp(30px, 9%, 55px);

          font-family: 'Peace Sans';
          color: #465299;
          font-weight: 400;
          font-size: clamp(14px, 1.4vw, 30px);

          cursor: pointer;
        
          .question {
            display: flex;
            flex-grow: 1;
            padding: clamp(10px, 2vh, 35px) 0;
            border-bottom: none;
          
          &.first {
            border-top: 1px solid #465299;
          }
          }
          .plus {  
            display: flex;
            justify-self:flex-end;
            margin-right: 4vw;

            width: clamp(24px, 1.25vw, 35px);
            height: clamp(24px, 1.25vw, 35px);
            background-color: #465299;
            border-radius: 50%;
          
            cursor: pointer;
            position: relative;

            transition: transform 0.3s ease;

            span {
              &::before, &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 50%;
              }

              &::before {
                height: 1px;
                background-color: #fff;
                transform: translate(-50%, -50%) rotate(0deg);
              }
            
              &::after {
                height: 1px;
                background-color: #fff;
                transform: translate(-50%, -50%) rotate(90deg);
              }
            }

            &.active {
              transform: rotate(-45deg);
              transition: transform 0.4s ease;
            }
          }
        }

        .content {
          display: grid;
          grid-template-columns: 4.8fr 1fr;
          gap: clamp(30px, 9%, 55px);
          max-height: 1px;
          overflow: hidden;
          transition: all 0.8s cubic-bezier(0,1,0,1);

          div {
            &:first-child {
              border-bottom: 1px solid #465299;
              border-top: 1px solid #465299;
              padding-bottom: clamp(10px, 2.3vh, 35px);
            }
          }

          .show_top_border {
            animation: showBorder 0.5s steps(1, end) forwards;
           }
           
           .hide_top_border {
            animation: hideBorder 0.1s forwards;
           }

           @keyframes showBorder {
            0% { border-top: 0px solid #465299; }
            100% { border-top: 1px solid #465299 !important; }
          }
          
          @keyframes hideBorder {
            0% { border-top: 1px solid #465299 !important; }
            100% { border-top: 0px solid #465299; }
          }
        
          

          font-family: 'Montserrat';
          color: #1D1D1B;
          font-weight: 400;
          font-size: clamp(12px, 0.9vw, 22px);
          line-height: clamp(15px, 2.5vh, 27px);
          // line-height: 27px;
          

          &.show {
            height: auto;
            max-height: 9999px;
            transition: all 1s cubic-bezier(1,0,1,0);
            // transition: all 1s linear;
          }
        }


      }
    }
  }


} 

@media screen and (max-width: 1000px) {
  .questions_block {
    min-height: 60rem;
    .container {
      
      &__title {
        margin-top: 3.2vh;
        margin-bottom: 3.1vh;
        span{
          font-size: clamp(16px, 6vw, 52px);
        }
        
      }

      .accordion {
        width: clamp(250px, 81.3vw, 700px);
        
        .item { 
          .title {
            grid-template-columns: 5.5fr 1fr;
            gap: 0;
            .plus {  
              margin-right: 0;
            }
          }

          .content {
            grid-template-columns: 5.5fr 1fr;
            gap: 0;
          }
        }
      }
    }

  }
}