@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
@font-face {
  font-family: "Airfool";
  src: url("../fonts/Airfool.eot");
  src: url("../fonts/Airfool.eot") format("embedded-opentype"), url("../fonts/Airfool.otf") format("otf"), url("../fonts/Airfool.svg") format("svg"), url("../fonts/Airfool.ttf") format("truetype"), url("../fonts/Airfool.woff") format("woff"), url("../fonts/Airfool.woff2") format("woff2");
}
@font-face {
  font-family: "Peace Sans";
  src: url("../fonts/Peace Sans.eot");
  src: url("../fonts/Peace Sans.eot") format("embedded-opentype"), url("../fonts/Peace Sans.otf") format("otf"), url("../fonts/Peace Sans.svg") format("svg"), url("../fonts/Peace Sans.ttf") format("truetype"), url("../fonts/Peace Sans.woff") format("woff"), url("../fonts/Peace Sans.woff2") format("woff2");
}
.prices_block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100svh;
  min-height: 100vh;
  width: 100vw;
  padding: 0 8.85vw;
  background-color: #57C5FF;
}
.prices_block .prices {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.prices_block .prices__title {
  text-align: center;
  width: 55%;
  height: 20%;
  padding-top: 0;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}
.prices_block .prices__title span {
  display: block;
  align-self: center;
  font-family: "Airfool";
  font-size: clamp(32px, 3.75vw, 72px);
  line-height: clamp(34px, 3.9vw, 74px);
  text-shadow: -1px 0 #465299, 0 1px #465299, 1px 0 #465299, 0 -1px #465299;
}
.prices_block .prices__title p {
  display: block;
  white-space: pre-wrap;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: clamp(12px, 1.5vw, 46px);
}
.prices_block .prices__container {
  position: relative;
  width: 100%;
  height: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.prices_block .prices__container .mySwiper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83%;
}
.prices_block .prices__container .card {
  display: grid;
  justify-content: center;
  background-color: #fff;
  border-radius: 30px;
  width: 100%;
  height: clamp(450px, 60.2vh, 850px);
  padding: clamp(18px, 0.8vh, 60px) clamp(30px, 2.3vw, 60px);
  grid-template-rows: 2fr 4fr 1fr 1fr;
  gap: 10px;
}
.prices_block .prices__container .card__title {
  font-family: "Airfool";
  margin-top: 1.9vh;
  color: #465299;
  font-size: 1.55vw;
}
.prices_block .prices__container .card__title span {
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}
.prices_block .prices__container .card__info {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  color: black;
}
.prices_block .prices__container .card__info p {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: clamp(12px, 0.93vw, 44px);
}
.prices_block .prices__container .card__price {
  font-family: "Peace Sans";
  color: #C34949;
  font-weight: 400;
  font-size: clamp(16px, 1.55vw, 54px);
  line-height: 51.7px;
}
.prices_block .prices__container .card button {
  height: 45px;
  width: 100%;
  border-radius: 30px;
  background-color: #C34949;
  transition: background-color 0.3s ease;
}
.prices_block .prices__container .card button span {
  font-family: "Airfool";
  color: #fff;
  font-size: clamp(14px, 1vw, 55px);
  text-shadow: -1px 0 #582523, 0 1px #582523, 1px 0 #582523, 0 -1px #582523;
}
.prices_block .prices__container .card button:hover {
  background-color: rgba(209, 37, 37, 0.9921568627);
}
@media screen and (max-width: 1222px) {
  .prices_block .prices__container .card {
    height: clamp(450px, 60.2vh, 450px);
    grid-template-rows: 1.5fr 5fr 1fr 1fr;
  }
  .prices_block .prices__container .card__title {
    font-size: clamp(16px, 1.85vw, 38px);
  }
  .prices_block .prices__container .card__info p {
    font-size: clamp(12px, 1.35vw, 46px);
  }
}
@media screen and (orientation: portrait) {
  .prices_block .prices__title {
    width: 90%;
  }
  .prices_block .prices__title span {
    font-size: clamp(26px, 6vw, 52px);
  }
  .prices_block .prices__title p {
    font-size: clamp(12px, 3vw, 46px);
  }
}/*# sourceMappingURL=prices.css.map */