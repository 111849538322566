@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
@font-face {
  font-family: "Peace Sans";
  src: url("../fonts/Peace Sans.eot");
  src: url("../fonts/Peace Sans.eot") format("embedded-opentype"), url("../fonts/Peace Sans.otf") format("otf"), url("../fonts/Peace Sans.svg") format("svg"), url("../fonts/Peace Sans.ttf") format("truetype"), url("../fonts/Peace Sans.woff") format("woff"), url("../fonts/Peace Sans.woff2") format("woff2");
}
@font-face {
  font-family: "Airfool";
  src: url("../fonts/Airfool.eot");
  src: url("../fonts/Airfool.eot") format("embedded-opentype"), url("../fonts/Airfool.otf") format("otf"), url("../fonts/Airfool.svg") format("svg"), url("../fonts/Airfool.ttf") format("truetype"), url("../fonts/Airfool.woff") format("woff"), url("../fonts/Airfool.woff2") format("woff2");
}
.certificates_block {
  position: relative;
  display: flex;
  min-height: 100svh;
  min-height: 100vh;
  width: 100vw;
  padding: 0 8.85vw;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(to bottom, #BFE2EF 10%, #63C6F8 100%);
}
.certificates_block .certificates {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.certificates_block .certificates__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10.4vh;
  gap: 22px;
  padding: 0 4.69vw;
  width: 100%;
  font-family: "Airfool";
  color: #fff;
  font-size: clamp(32px, 3.75vw, 72px);
  line-height: clamp(34px, 3.9vw, 74px);
  padding: 0 8.85vw;
  text-shadow: -1px 0 #465299, 0 1px #465299, 1px 0 #465299, 0 -1px #465299;
}
.certificates_block .certificates__container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.certificates_block .certificates__container .mySwiper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83%;
  height: 100%;
}
.certificates_block .certificates__container .mySwiper .mySwiperSlide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.certificates_block .certificates__container .mySwiper .mySwiperSlide .certificate {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 63vh;
}
.certificates_block .certificates__container .mySwiper .mySwiperSlide .certificate .certificate_img {
  width: 100%;
  height: 100%;
}

@media screen and (orientation: portrait) {
  .certificates_block {
    min-height: 50vh;
  }
  .certificates_block .certificates__title {
    font-size: clamp(16px, 6vw, 52px);
  }
}/*# sourceMappingURL=certificates.css.map */