@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

@font-face {
  font-family: 'Peace Sans';
  src: url('../fonts/Peace\ Sans.eot');
  src: url('../fonts/Peace\ Sans.eot') format('embedded-opentype'),
  url('../fonts/Peace\ Sans.otf') format('otf'),
  url('../fonts/Peace\ Sans.svg') format('svg'),
  url('../fonts/Peace\ Sans.ttf') format('truetype'),
  url('../fonts/Peace\ Sans.woff') format('woff'),
  url('../fonts/Peace\ Sans.woff2') format('woff2');
}

@font-face {
  font-family: 'Airfool';
  src: url('../fonts/Airfool.eot');
  src: url('../fonts/Airfool.eot') format('embedded-opentype'),
       url('../fonts/Airfool.otf') format('otf'),
       url('../fonts/Airfool.svg') format('svg'),
       url('../fonts/Airfool.ttf') format('truetype'),
       url('../fonts/Airfool.woff') format('woff'),
       url('../fonts/Airfool.woff2') format('woff2');
}


.footer_block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 30svh;
  min-height: 30vh;
  width: 100vw;
  background-color: #295233;

  .contacts_block {
    display: flex;
    justify-content: space-around;

    .contacts {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      color: #fff;

      span:nth-child(1), span:nth-child(2), 
      span:nth-child(3)  {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: clamp(20px, 1.35vw, 30px);
      }
    
      span:nth-child(4) {
        font-family: 'Cruinn';
        font-weight: 500;
        font-size: clamp(20px, 1.35vw, 32px);
        margin-bottom: 0.5rem;
      }

      .icons_group {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .icon {
          width: clamp(29px, 2.5vw, 55px);
          height: clamp(29px, 2.5vw, 55px);
          color: black;
          cursor: pointer;
          // transition: filter 0.3s linear;
  
          &.telegram {
            &:hover {
              filter: brightness(0) saturate(100%) invert(91%) sepia(100%) saturate(7224%) hue-rotate(176deg) brightness(101%) contrast(106%);
            }
          }
          &.whatsapp {
            &:hover {
              filter: brightness(0) saturate(100%) invert(97%) sepia(27%) saturate(5396%) hue-rotate(308deg) brightness(132%) contrast(75%);
            }
          }
          &.vk {
            &:hover {
              filter: brightness(0) saturate(100%) invert(77%) sepia(8%) saturate(2739%) hue-rotate(169deg) brightness(98%) contrast(96%);
              // filter: brightness(0) saturate(100%) invert(79%) sepia(23%) saturate(909%) hue-rotate(3deg) brightness(95%) contrast(94%);
              
            }
          }
          &.instagram {
            &:hover {
              filter: brightness(0) saturate(100%) invert(88%) sepia(5%) saturate(5207%) hue-rotate(300deg) brightness(112%) contrast(116%);
              // filter: brightness(0) saturate(100%) invert(54%) sepia(15%) saturate(7116%) hue-rotate(324deg) brightness(103%) contrast(99%);
              
            }
          }
        }
      }
    }


    .company_info {
      display: flex;
      flex-direction: column;
      gap: 15px;
      color: #fff;
      span {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: clamp(20px, 1.35vw, 30px);

        // &:last-child {
        //   font-weight: 300 !important;
        //   font-size: clamp(12px, 0.6vw, 30px);
        // }
      }
    }



    .logo_btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      color: #fff;
      

      .logo {
        width: clamp(200px, 15.8vw, 400px);
        cursor: pointer; 
        &:hover {
          filter: brightness(0) saturate(100%) invert(100%) sepia(17%) saturate(2753%) hue-rotate(9deg) brightness(88%) contrast(96%);
          // filter: brightness(0) saturate(100%) invert(93%) sepia(33%) saturate(6211%) hue-rotate(310deg) brightness(93%) contrast(94%);
          // filter: brightness(0) saturate(100%) invert(59%) sepia(55%) saturate(277%) hue-rotate(164deg) brightness(101%) contrast(92%);
        }
        // filter: brightness(0) saturate(100%) invert(86%) sepia(13%) saturate(5892%) hue-rotate(165deg) brightness(96%) contrast(95%);
      }

      span {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: clamp(20px, 1.35vw, 30px);
      }
    }
  
  }

  @media screen and (max-width: 700px) {
    .contacts_block {
      justify-content: flex-start;
      margin-left: 16.27vw;
      .logo_btn {
        display: none;
      }
    }

  
  }

}