@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
@font-face {
  font-family: "Peace Sans";
  src: url("../fonts/Peace Sans.eot");
  src: url("../fonts/Peace Sans.eot") format("embedded-opentype"), url("../fonts/Peace Sans.otf") format("otf"), url("../fonts/Peace Sans.svg") format("svg"), url("../fonts/Peace Sans.ttf") format("truetype"), url("../fonts/Peace Sans.woff") format("woff"), url("../fonts/Peace Sans.woff2") format("woff2");
}
@font-face {
  font-family: "Airfool";
  src: url("../fonts/Airfool.eot");
  src: url("../fonts/Airfool.eot") format("embedded-opentype"), url("../fonts/Airfool.otf") format("otf"), url("../fonts/Airfool.svg") format("svg"), url("../fonts/Airfool.ttf") format("truetype"), url("../fonts/Airfool.woff") format("woff"), url("../fonts/Airfool.woff2") format("woff2");
}
.footer_block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 30svh;
  min-height: 30vh;
  width: 100vw;
  background-color: #295233;
}
.footer_block .contacts_block {
  display: flex;
  justify-content: space-around;
}
.footer_block .contacts_block .contacts {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  color: #fff;
}
.footer_block .contacts_block .contacts span:nth-child(1), .footer_block .contacts_block .contacts span:nth-child(2),
.footer_block .contacts_block .contacts span:nth-child(3) {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: clamp(20px, 1.35vw, 30px);
}
.footer_block .contacts_block .contacts span:nth-child(4) {
  font-family: "Cruinn";
  font-weight: 500;
  font-size: clamp(20px, 1.35vw, 32px);
  margin-bottom: 0.5rem;
}
.footer_block .contacts_block .contacts .icons_group {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.footer_block .contacts_block .contacts .icons_group .icon {
  width: clamp(29px, 2.5vw, 55px);
  height: clamp(29px, 2.5vw, 55px);
  color: black;
  cursor: pointer;
}
.footer_block .contacts_block .contacts .icons_group .icon.telegram:hover {
  filter: brightness(0) saturate(100%) invert(91%) sepia(100%) saturate(7224%) hue-rotate(176deg) brightness(101%) contrast(106%);
}
.footer_block .contacts_block .contacts .icons_group .icon.whatsapp:hover {
  filter: brightness(0) saturate(100%) invert(97%) sepia(27%) saturate(5396%) hue-rotate(308deg) brightness(132%) contrast(75%);
}
.footer_block .contacts_block .contacts .icons_group .icon.vk:hover {
  filter: brightness(0) saturate(100%) invert(77%) sepia(8%) saturate(2739%) hue-rotate(169deg) brightness(98%) contrast(96%);
}
.footer_block .contacts_block .contacts .icons_group .icon.instagram:hover {
  filter: brightness(0) saturate(100%) invert(88%) sepia(5%) saturate(5207%) hue-rotate(300deg) brightness(112%) contrast(116%);
}
.footer_block .contacts_block .company_info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: #fff;
}
.footer_block .contacts_block .company_info span {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: clamp(20px, 1.35vw, 30px);
}
.footer_block .contacts_block .logo_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  color: #fff;
}
.footer_block .contacts_block .logo_btn .logo {
  width: clamp(200px, 15.8vw, 400px);
  cursor: pointer;
}
.footer_block .contacts_block .logo_btn .logo:hover {
  filter: brightness(0) saturate(100%) invert(100%) sepia(17%) saturate(2753%) hue-rotate(9deg) brightness(88%) contrast(96%);
}
.footer_block .contacts_block .logo_btn span {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: clamp(20px, 1.35vw, 30px);
}
@media screen and (max-width: 700px) {
  .footer_block .contacts_block {
    justify-content: flex-start;
    margin-left: 16.27vw;
  }
  .footer_block .contacts_block .logo_btn {
    display: none;
  }
}/*# sourceMappingURL=footer.css.map */