@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
@font-face {
  font-family: 'Peace Sans';
  src: url('../fonts/Peace\ Sans.eot');
  src: url('../fonts/Peace\ Sans.eot') format('embedded-opentype'),
  url('../fonts/Peace\ Sans.otf') format('otf'),
  url('../fonts/Peace\ Sans.svg') format('svg'),
  url('../fonts/Peace\ Sans.ttf') format('truetype'),
  url('../fonts/Peace\ Sans.woff') format('woff'),
  url('../fonts/Peace\ Sans.woff2') format('woff2');
}
@font-face {
  font-family: 'Airfool';
  src: url('../fonts/Airfool.eot');
  src: url('../fonts/Airfool.eot') format('embedded-opentype'),
       url('../fonts/Airfool.otf') format('otf'),
       url('../fonts/Airfool.svg') format('svg'),
       url('../fonts/Airfool.ttf') format('truetype'),
       url('../fonts/Airfool.woff') format('woff'),
       url('../fonts/Airfool.woff2') format('woff2');



}

.welcome_block {
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  min-height: 100vh;
  width: 100vw;
  background-color: #9EDAEE;
  background-image: url('/src/img/welcome_block/background.svg');
  // position: absolute;
  background-size: cover;
  background-position: center;
 
  .header {
    display: flex;
    flex-direction: row;
    // gap: 15px;
    align-items: center;

    width: 100vw;
    height: 8.33vh;
    padding: 10px 5.6vw;
    background-color: #5FC9EE;
    .logo {
      border: 3px solid #fff;

      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      white-space: nowrap;
    
      height: 6.98vh;
      // margin-left: 5.6vw;
      padding: 0 1.3vw;
      cursor: pointer;
      span {
        text-align: center;
        font-family: 'Airfool';
        color: #fff;
        font-size: clamp(13px, 1.25vw, 36px);
        line-height: clamp(12px, 1.6vw, 27px);
      }


    }

    .nav_menu {
      display: flex;
      align-items: center;
      margin: 0 1.6vw;
      width: 41.4vw;

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: clamp(15px, 2.4vw, 60px);
        list-style: none;
        width: 100%;

        font-weight: 600;
        font-size: clamp(12px, 0.83vw, 28px);
        font-family: 'Montserrat', sans-serif;
        // color: #F8F7F2;
        
      }

      li {
        color: #F8F7F2;
        cursor: pointer;
        white-space: nowrap;
        

        a {
          color: #fff;
          transition: color  0.3s ease;
          &:hover {
            color: #5a6cd7;
          }
        }

      }

      button {
        display: flex;
        align-items: center;
        text-align: center;
        font-family: 'Peace Sans';
        font-size: clamp(12px, 0.83vw, 28px);
        line-height: clamp(11px, 0.8vw, 26px);
        white-space: nowrap;
        width: auto;
        height: 4.5vh;
        padding: 1.45vh 0.83vw;
  
        margin-left: 14.4vw;
        // margin-right: 8.85vw;
        // margin-right: 0;
        
        background-color: #fff;
        color: #465299;
        border-radius: 2px;
        transition: all 0.3s ease;

        a {
          color: #465299;
        }

        &:hover {
          background-color: #f6f6fc;
          color: #5a6cd7;
          a {
            color: #5a6cd7;
          }
        }
      } 
    }
    @media (max-width: 1264px) {
      ul {
        button {
          margin-left: 4.4vw !important;
        }
      }
    }
    @media (max-width: 980px) {
    // @media (max-width: 1320px) {

      .logo {
        width: clamp(150px, 51vw, 500px);
        margin-left: 8.35vw;
        span {
          font-size: clamp(13px, 4.25vw, 36px);
        }
      }
  
      .nav_menu {
        display: flex;
        // opacity: 0;
        position: fixed;

        // top:  100%;
        // transform: translateY(-100%);
  
        flex-direction: column;
        // height: clamp(370px, 44.8vh, 700px);
        height: 47.8vh;
        width: 100vw;
        padding-top: 16%;
        padding-bottom: 9%;
        margin: 0;
        // top:0; 
        bottom:0; 
        left:0; 
        right:0;
        z-index: 50;
        overflow-y: auto;
        background-color: #5FC9EE;
  
        animation: burgerAnimation 0.5s;
        // transition: transform  0.6s ease-in-out, opacity  0.1s ease-in-out;
        // transition: transform  0.6s ease-in, opacity  0.1s ease-in;
        transition: top  0.6s ease-in-out, opacity  0.2s ease-in-out, transform  0.6s ease-in-out;
        ul {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: clamp(10px, 2.6vh, 35px);
          font-size: clamp(14px, 3.7vw, 28px);
  
          button {
            width: auto;
            height: auto;
            padding: 14px 16px;
            font-size: clamp(15px, 3.7vw, 25px);
            margin-left: 0;
            margin-right: 0;
            margin-top: clamp(15px, 0.9vh, 45px);
          }
        }
  
        &.open {
          // display: flex !important;
          top:  0; 
          opacity: 1;
          transform: translateY(0);
          
        }
  
        &.close {
          top:  -100%; 
          transform: translateY(100%);
          opacity: 0;
          
        }
  
        @keyframes burgerAnimation {
          from {opacity: 0}
          to {opacity: 1}
        }
      }
  
      .burger_btn {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 8.3vw;
        z-index: 51;
        width: 37.5px;
        height: 27px;
        cursor: pointer;
  
        span {
          height: 4px;
          // border-radius: 3px;
          width: 100%;
          transform: scale(1);
          background-color: #fff;
        }
      
        &:before {
          content: '';
          position: absolute;
          top: 0;
          width: 37.5px;
          background-color:  #fff;
          height: 4px;
          transition: all 0.3s ease 0s;
          top: 0;
        }
  
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: 37.5px;
          background-color: #fff;
          height: 4px;
          transition: all 0.3s ease 0s;
          bottom: 0;
        }
  
        &.active {
          position: fixed;
          top: 2.5%;
          left: 64vw;
          span {
            transform: scale(0);
          }
  
          &:before {
            top:50%;
            transform: rotate(-45deg) translate(0, -50%);
          }
  
          &:after {
            bottom: 50%;
            transform: rotate(45deg) translate(0, 50%);
          }
        } 
  
  
      }
    }
       
  }


  .main {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    flex-grow: 1;
    padding-top: 12.5vh;
    padding-left: 8.85vw; 
    padding-right: 8.85vw; 
    gap: clamp(80px, 5.7vw, 200px);

    .monologue_block {
      position: relative;
      display: block;
      padding-bottom: 24.48vh;
      padding-top: 12.5vh;
      .rectangle {
        position: relative;
        width: clamp(280px, 37.3vw, 850px);
        height: 100%;
        z-index: 3;
  
        background-color: #fff;
        border-radius: 40px;

        .monologue_text {
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 7vh 1.6vw;
          gap: 0.2em;

          .name {
            font-family: 'Airfool';
            color: #465299;
            font-size: clamp(25px, 3.3vw, 70px);
            text-shadow: 1px 0px 0px #fff, 
               0px 1px 0px #fff, 
               -1px 0px 0px #fff, 
               0px -1px 0px #fff;
            // line-height: clamp(32px, 4.125vh, 46px);
            line-height: clamp(32px, 4.2vh, 46px);
          }
          .school {
            font-family: 'Peace Sans';
            color: #465299;
            font-size: clamp(15px, 1.7vw, 42px);
            // line-height: clamp(32px, 4.125vh, 46px);
          }
          .introduction {
            font-family: 'Montserrat';
            color: #1B3D63;
            font-weight: 400;
            font-size: clamp(11px, 1.25vw, 34px);
            // line-height: clamp(16px, 1.4rem, 31px);
          }
          .try_button {
            z-index: 4;
            margin-top: 1.4em;
            font-family: 'Airfool';
            white-space: nowrap;
            background-color: #C34949;
            text-shadow: 1px 0px 0px #582523, 
               0px 1px 0px #582523, 
               -1px 0px 0px #582523, 
               0px -1px 0px #582523;
            font-size: clamp(12px, 1.35vw, 36px);
            color: #fff;
            border-radius: 30px;
            width: clamp(200px, 24.6vw, 830px);
            height: auto;
            padding: clamp(11px, 2.4vh, 25px) 1.5vw;

            transition: background-color 0.3s ease;

            a {
              color:#fff;
            }

            &:hover {
              background-color: #d12525fd;
            }
          }
        }

        .triangle {
          position: absolute;
          bottom: -13.4vh;
          right: 5.3vw;
          width: 0;
          height: 0;
          border-top: 15vh solid #fff;
          border-left: 10.4vw solid transparent;
          border-bottom: 0;
          border-right: 0;
  
        }

      }

    }

    .famaly_block {
      position: relative;
      display: flex;
      flex-grow: 1;
      overflow: hidden;
      align-items: flex-end;
      width: 100%;
      height: 78.6vh;
      z-index: 1;

      .family_img {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(0, 5%);
        width: 100%;
        height: auto;
        z-index: 3;
      }

      .paper_plain_img_phone {
        display: none;
      }

    }

    .paper_plain_img {
      position: absolute;
      width: 15%;
      height: auto;
      // top: 12.4%;
      top: calc(12.5vh - 2%);
      left: 55.5%;
      z-index: 2;
      transition: left 2s ease-out, top 2s ease-out;


      &.fly_away {
        top: 70vh;
        left: -30vw;
        z-index: 100;
      }
    }


  }

  // @media screen and (max-width: 1100px) {
  @media screen and (orientation: portrait) {
    .main {
      flex-direction: column;
      align-items: center;
      padding-top: clamp(35px, 4.8vh, 55px);
      padding-left: 0;
      padding-right: 0;

      .monologue_block {
        padding-left: 8.85vw;
        padding-right: 8.85vw;  
        padding-top: 0;
        padding-bottom: 0;
        height: clamp(190px, 21.8vh, 260px);

        .rectangle {
          width: clamp(240px, 83.95vw, 600px);

          .monologue_text {
            padding: 3.5vh clamp(20px, 6.5vw, 40px);

            .name {
              font-size: clamp(20px, 7vw, 46px);
              line-height: clamp(22px, 2.8vh, 32px);

            } 
            .school {
              font-size: clamp(12px, 3.6vw, 26px);
              line-height: clamp(14px, 2.9vh, 28px);
            } 
            .introduction {
              font-size: clamp(10px, 2.7vw, 18px);
              line-height: clamp(12px, 1rem, 26px);
            }

            .try_button {
              font-size: clamp(12px, 2vw, 36px);
              width: clamp(200px, 35vw, 600px);
              padding: clamp(11px, 1vh, 25px) 1.5vw
            }
          }

          .triangle {
            border-top: 7.7vh solid #fff;
            border-left: clamp(80px, 23vw, 170px) solid transparent;
            right: clamp(20px, 12vw, 80px);
            bottom: -6.4vh;
          }
        }
      }

      .famaly_block {
        // padding-left: 8.85vw;
        // padding-right: 8.85vw;  
        height: 100%;

        .family_img {
          width: calc(100vw - 2 * 8.85vw);
          left:8.85vw;
          right:8.85vw;
        }

        .paper_plain_img_phone {
          position: absolute;
          width: 25%;
          height: auto;
          z-index: 3;
          top: 0;
          left: 20%;

          transition: left 1.5s ease-out, top 1.5s ease;

          &.fly_away {
            top: 30vh;
            left: -30vw;
            z-index: 100;
          }
        }
      }

      .paper_plain_img {

        display: none;
        
        // width: 15%;
        // height: auto;
        // top: calc(12.5vh - 2%);
        // left: 55.5%;

        width: 20%;
        // top: top(8.33vh + 4.8vh + 21.8vh);
        // top: clamp(305px, calc(26.6vh + 5.7vw), 515px);
        top: 0;
        left: 25%;
        // z-index: 2;
   

      }
    }
  }

  @media (aspect-ratio:  5/4), (aspect-ratio:  4/3) {
    .main {
      .monologue_block {
        padding-bottom: 30.48vh;
        .rectangle {
          .monologue_text {
            padding: 5vh 1.6vw;
          }
  
          .triangle {
            bottom: -8.4vh;
            border-top: 10vh solid #fff;
          }
        }
      }
  
      .famaly_block {
        .family_img {
          transform: translate(0, 6vh); 
          height: 100%; 
        }
  
      }

    }
  }

}
