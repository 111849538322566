.swiper_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(22px, 2.76vw, 100px);
  height: clamp(22px, 2.76vw, 100px);
  background-color: rgba(255, 255, 255, 0.83);
  color: #222222;
  border-radius: 50%;
  cursor: pointer;
}
.swiper_button img {
  height: clamp(9px, 3.125vh, 90px);
  width: clamp(7px, 0.99vw, 90px);
}

.swiper-button-next {
  background-color: rgba(255, 255, 255, 0.83);
  border-radius: 100px;
  width: clamp(30px, 2.76vw, 50px);
  height: clamp(30px, 2.76vw, 50px);
  background-image: url(../img/arrows/right_arrov.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.swiper-button-next::after {
  display: none;
  --swiper-navigation-size: 0;
}

.swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.83);
  border-radius: 100px;
  width: clamp(30px, 2.76vw, 50px);
  height: clamp(30px, 2.76vw, 50px);
  background-image: url(../img/arrows/left_arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  --swiper-navigation-size: clamp(30px, 2.76vw, 50px);
}
.swiper-button-prev::after {
  display: none;
}/*# sourceMappingURL=buttons.css.map */