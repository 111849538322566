.main_container {
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  transition: scroll-snap-align 4s ease;
  scroll-behavior: smooth;
}
.main_container .item_container {
  height: 100%;
  width: 100%;
  scroll-snap-align: center;
  transition: scroll-snap-align 4s ease;
  scroll-behavior: smooth;
}
.main_container .item_container a {
  color: inherit;
  text-decoration: none;
}
.main_container .item_container__last {
  height: 27.5%;
  width: 100%;
  scroll-snap-align: end;
}/*# sourceMappingURL=app_block.css.map */