@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
@font-face {
  font-family: "Peace Sans";
  src: url("../fonts/Peace Sans.eot");
  src: url("../fonts/Peace Sans.eot") format("embedded-opentype"), url("../fonts/Peace Sans.otf") format("otf"), url("../fonts/Peace Sans.svg") format("svg"), url("../fonts/Peace Sans.ttf") format("truetype"), url("../fonts/Peace Sans.woff") format("woff"), url("../fonts/Peace Sans.woff2") format("woff2");
}
@font-face {
  font-family: "Airfool";
  src: url("../fonts/Airfool.eot");
  src: url("../fonts/Airfool.eot") format("embedded-opentype"), url("../fonts/Airfool.otf") format("otf"), url("../fonts/Airfool.svg") format("svg"), url("../fonts/Airfool.ttf") format("truetype"), url("../fonts/Airfool.woff") format("woff"), url("../fonts/Airfool.woff2") format("woff2");
}
.about_block {
  position: relative;
  display: flex;
  min-height: 100svh;
  min-height: 100vh;
  width: 100vw;
  background-image: url("/src/img/about_block/cloud_back.svg");
  background-color: #9EDAEE;
  background-size: cover;
  background-position: center;
}
.about_block .about_text {
  position: absolute;
  width: 50.5vw;
  top: 15vh;
  left: 8.9vw;
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  color: #fff;
}
.about_block .about_text .title {
  display: block;
  font-family: "Airfool";
  font-size: clamp(26px, 3.3vw, 72px);
  line-height: clamp(26px, 3.5vh, 50px);
  text-shadow: 1px 0px 0px #465299, 0px 1px 0px #465299, -1px 0px 0px #465299, 0px -1px 0px #465299;
}
.about_block .about_text .description {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}
.about_block .about_text .description span {
  display: block;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: clamp(14px, 2vw, 42px);
  line-height: clamp(12px, 2.3vh, 36px);
}
.about_block .about_text .description__text {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: clamp(14px, 1.35vw, 36px);
  line-height: clamp(10px, 2.4vh, 36px);
  gap: 0.25em;
}
.about_block .teacher_clouds {
  position: relative;
  top: 2.9vh;
  left: 65.1vw;
  width: 26.9vw;
  height: 37.66vw;
}
.about_block .teacher_clouds .teacher__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.about_block .teacher_clouds .teacher__img__img {
  width: 100%;
  height: 100%;
}
.about_block .teacher_clouds .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: "Peace Sans";
  color: #465299;
  font-size: clamp(6px, 1.04vw, 24px);
  line-height: clamp(20px, 1.55vw, 34px);
}
.about_block .teacher_clouds .cloud_left {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17vw;
  display: flex;
  bottom: 22%;
  left: -20%;
}
.about_block .teacher_clouds .cloud_left__img {
  width: 100%;
}
.about_block .teacher_clouds .cloud_left .left_content {
  position: absolute;
  padding-top: 3vw;
  padding-bottom: 1.1vw;
}
.about_block .teacher_clouds .cloud_right {
  position: absolute;
  width: 20.8vw;
  display: flex;
  bottom: 45%;
  right: 0;
}
.about_block .teacher_clouds .cloud_right__img {
  width: 100%;
}
.about_block .teacher_clouds .cloud_right .right_content {
  position: absolute;
  padding-top: 3vw;
  padding-bottom: 1.1vw;
}
.about_block .plain_block {
  position: absolute;
  left: -40vw;
  top: 34.7vh;
  width: 54vw;
  transition: left 1s ease-out, top 1s ease-out;
}
.about_block .plain_block__img {
  width: 100%;
  height: 100%;
}
.about_block .plain_block.visible {
  left: 10.9vw;
  top: 44.7vh;
}
@media screen and (orientation: portrait) {
  .about_block .about_text {
    top: 3.6vh;
    left: 10.4vw;
    width: 80vw;
    gap: 1.46vh;
  }
  .about_block .about_text .title {
    font-size: clamp(26px, 6vw, 52px);
  }
  .about_block .about_text .description {
    gap: clamp(5px, 0.9rem, 8px);
  }
  .about_block .about_text .description span {
    font-size: clamp(13px, 3.25vw, 48px);
  }
  .about_block .about_text .description__text {
    gap: clamp(3px, 0.7rem, 6px);
    font-size: clamp(12px, 3.05vw, 36px);
    line-height: clamp(14px, 1.9vh, 32px);
  }
  .about_block .teacher_clouds {
    top: 31vh;
    left: 27.67vw;
    width: 44.5vw;
    height: 66.75vw;
  }
  .about_block .teacher_clouds .text {
    font-size: 2.4vw;
    line-height: clamp(17px, 1.7vh, 30px);
  }
  .about_block .teacher_clouds .cloud_left {
    width: 39.4vw;
    bottom: 36%;
    left: -35%;
  }
  .about_block .teacher_clouds .cloud_right {
    width: 45.4vw;
    bottom: 66%;
    right: -33%;
  }
  .about_block .plain_block {
    width: 91.6vw;
    top: 55vh;
    left: -50vw;
  }
  .about_block .plain_block.visible {
    top: 63vh;
    left: 7vw;
  }
}/*# sourceMappingURL=about.css.map */