@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
@font-face {
  font-family: "Airfool";
  src: url("../fonts/Airfool.eot");
  src: url("../fonts/Airfool.eot") format("embedded-opentype"), url("../fonts/Airfool.otf") format("otf"), url("../fonts/Airfool.svg") format("svg"), url("../fonts/Airfool.ttf") format("truetype"), url("../fonts/Airfool.woff") format("woff"), url("../fonts/Airfool.woff2") format("woff2");
}
.feedback_block {
  position: relative;
  background-color: #57C5FF;
  min-height: 100svh;
  min-height: 100vh;
  width: 100vw;
}
.feedback_block .container {
  padding: 0 8.85vw;
}
.feedback_block .container__title {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
  top: 7.3vh;
  width: 100%;
  font-family: "Airfool";
  color: #fff;
  font-size: clamp(32px, 3.75vw, 72px);
  line-height: clamp(34px, 3.9vw, 74px);
  padding: 0 8.85vw;
  text-shadow: -1px 0 #465299, 0 1px #465299, 1px 0 #465299, 0 -1px #465299;
}
.feedback_block .container__swiper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.feedback_block .container__swiper .mySwiperSlide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.feedback_block .container__swiper .mySwiperSlide .feedback {
  width: clamp(300px, 53.5vw, 1200px);
  height: clamp(300px, 30vw, 600px);
  padding: 5.2vh 5.2vw;
  background-color: rgba(255, 255, 255, 0.83);
  border-radius: 50px;
  margin: 0 clamp(30px, 11.7vw, 300px);
}
@media screen and (max-width: 500px) {
  .feedback_block .container__swiper .mySwiperSlide .feedback {
    margin: 0 clamp(5px, 3vw, 100px);
  }
}
.feedback_block .container__swiper .mySwiperSlide .feedback.like_text {
  display: grid;
  gap: 27px;
  grid-template-rows: 88% 12%;
  font-family: "Montserrat";
  color: #1D3557;
}
.feedback_block .container__swiper .mySwiperSlide .feedback.like_text p {
  display: block;
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  width: 100%;
  font-weight: 400;
  font-size: clamp(12px, 1.3vw, 40px);
}
.feedback_block .container__swiper .mySwiperSlide .feedback.like_text span {
  display: block;
  height: 100%;
  width: 100%;
  font-weight: 600;
  font-size: clamp(18px, 1.56vw, 50px);
}
.feedback_block .container__swiper .mySwiperSlide .feedback.like_video {
  display: flex;
  align-items: center;
  justify-content: center;
}
.feedback_block .container__swiper .mySwiperSlide .feedback.like_video .video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feedback_block .container__swiper .mySwiperSlide .feedback.like_video .video .screensaver_img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.feedback_block .container__swiper .mySwiperSlide .feedback.like_video .video .play_btn {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 7.8vw;
  height: 7.8vw;
  cursor: pointer;
}
.feedback_block .container__swiper .mySwiperSlide .feedback.like_video .video iframe, .feedback_block .container__swiper .mySwiperSlide .feedback.like_video .video img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
@media screen and (orientation: portrait) {
  .feedback_block .container__title {
    font-size: clamp(16px, 6vw, 52px);
  }
  .feedback {
    width: clamp(300px, 53.5vw, 1200px);
    height: clamp(300px, 30vw, 800px);
    padding: 5.2vh 5.2vw;
  }
}/*# sourceMappingURL=feedbacks.css.map */