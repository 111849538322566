@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
@font-face {
  font-family: 'Airfool';
  src: url('../fonts/Airfool.eot');
  src: url('../fonts/Airfool.eot') format('embedded-opentype'),
       url('../fonts/Airfool.otf') format('otf'),
       url('../fonts/Airfool.svg') format('svg'),
       url('../fonts/Airfool.ttf') format('truetype'),
       url('../fonts/Airfool.woff') format('woff'),
       url('../fonts/Airfool.woff2') format('woff2');
}
@font-face {
  font-family: 'Peace Sans';
  src: url('../fonts/Peace\ Sans.eot');
  src: url('../fonts/Peace\ Sans.eot') format('embedded-opentype'),
  url('../fonts/Peace\ Sans.otf') format('otf'),
  url('../fonts/Peace\ Sans.svg') format('svg'),
  url('../fonts/Peace\ Sans.ttf') format('truetype'),
  url('../fonts/Peace\ Sans.woff') format('woff'),
  url('../fonts/Peace\ Sans.woff2') format('woff2');
}


.prices_block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100svh;
  min-height: 100vh;
  width: 100vw;
  padding: 0 8.85vw;
  // padding: 8.85vw 4.17vh;
  background-color: #57C5FF;

  .prices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__title {
      // position: absolute;
      text-align: center;
      // top: 7.3vh;
      width: 55%;
      height: 20%;
      // padding-top: 7.3vh;
      padding-top: 0;
      gap: 0.5rem;


      // padding: 0 8.85vw;
    
      display: flex;
      flex-direction: column;
      // align-items: flex-start;
      justify-content: center;
      // gap: clamp(5px, 1.9vh, 25px);
      // padding: 0 4.69vw;
      color: #fff;
      //margin-bottom: clamp(11px, 3.1vh, 35px);

      span {
        display: block;
        align-self: center;
        font-family: 'Airfool';
        font-size: clamp(32px, 3.75vw, 72px);
        line-height: clamp(34px, 3.9vw, 74px);
        text-shadow: -1px 0 #465299, 
                      0 1px #465299, 
                      1px 0 #465299, 
                      0 -1px #465299;
      }

      p {
        display: block;
        white-space: pre-wrap;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: clamp(12px, 1.5vw, 46px);
      }
    }

    &__container {
      position: relative;
      width: 100%;
      height: 80%;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      // padding: 40px 0;

      .mySwiper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 83%;
      }
  
      .card {
        display: grid;
        justify-content: center;
        background-color: #fff;
        border-radius: 30px;
        width: 100%;
        height: clamp(450px, 60.2vh, 850px);
        padding: clamp(18px, 0.8vh, 60px) clamp(30px, 2.3vw, 60px);
        // grid-template-rows: 25% 40% 17% 18%;
        grid-template-rows: 2fr 4fr 1fr 1fr;
        gap: 10px;

        &__title {
          font-family: 'Airfool';
          margin-top: 1.9vh;
          color: #465299;
          font-size: 1.55vw;
        
          span {
            text-shadow: -1px 0 #fff, 
                          0 1px #fff, 
                          1px 0 #fff, 
                          0 -1px #fff;
          }
        }

        &__info {
          display: flex;
          flex-direction: column;
          // gap: 11px;
          gap: 1.3rem;
          color: black;
          p {
            font-family: 'Montserrat';
            font-weight: 400;
            font-size: clamp(12px, 0.93vw, 44px);
          }
        }

        &__price {
          font-family: 'Peace Sans';
          color: #C34949;
          font-weight: 400;
          font-size: clamp(16px, 1.55vw, 54px);
          line-height: 51.7px;
        }

        button {
          height: 45px;
          width: 100%;
          border-radius: 30px;
          background-color: #C34949;
          span {
            font-family: 'Airfool';
            color: #fff;
            font-size: clamp(14px, 1vw, 55px);
            text-shadow: -1px 0 #582523, 0 1px #582523, 1px 0 #582523, 0 -1px #582523;
          }

          transition: background-color 0.3s ease;

          &:hover {
            background-color: #d12525fd;
          }
        }
      }

      @media screen and (max-width: 1222px) {
        .card {
          height: clamp(450px, 60.2vh, 450px);
          // grid-template-rows: 22% 43% 17% 18%;
          grid-template-rows: 1.5fr 5fr 1fr 1fr;
          &__title {
            font-size: clamp(16px, 1.85vw, 38px);
          }
          &__info {
            p {
              font-size: clamp(12px, 1.35vw, 46px);
            }
          }

        }
      }

    }
  }

  @media screen and (orientation: portrait) {
    .prices {
      &__title {
        width: 90%;
        span {
          font-size: clamp(26px, 6vw, 52px);
        }
        p {
          font-size: clamp(12px, 3vw, 46px);
        }
      }
    }

  }
}
