@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
@font-face {
  font-family: "Peace Sans";
  src: url("../fonts/Peace Sans.eot");
  src: url("../fonts/Peace Sans.eot") format("embedded-opentype"), url("../fonts/Peace Sans.otf") format("otf"), url("../fonts/Peace Sans.svg") format("svg"), url("../fonts/Peace Sans.ttf") format("truetype"), url("../fonts/Peace Sans.woff") format("woff"), url("../fonts/Peace Sans.woff2") format("woff2");
}
@font-face {
  font-family: "Airfool";
  src: url("../fonts/Airfool.eot");
  src: url("../fonts/Airfool.eot") format("embedded-opentype"), url("../fonts/Airfool.otf") format("otf"), url("../fonts/Airfool.svg") format("svg"), url("../fonts/Airfool.ttf") format("truetype"), url("../fonts/Airfool.woff") format("woff"), url("../fonts/Airfool.woff2") format("woff2");
}
.welcome_block {
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  min-height: 100vh;
  width: 100vw;
  background-color: #9EDAEE;
  background-image: url("/src/img/welcome_block/background.svg");
  background-size: cover;
  background-position: center;
}
.welcome_block .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  height: 8.33vh;
  padding: 10px 5.6vw;
  background-color: #5FC9EE;
}
.welcome_block .header .logo {
  border: 3px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  white-space: nowrap;
  height: 6.98vh;
  padding: 0 1.3vw;
  cursor: pointer;
}
.welcome_block .header .logo span {
  text-align: center;
  font-family: "Airfool";
  color: #fff;
  font-size: clamp(13px, 1.25vw, 36px);
  line-height: clamp(12px, 1.6vw, 27px);
}
.welcome_block .header .nav_menu {
  display: flex;
  align-items: center;
  margin: 0 1.6vw;
  width: 41.4vw;
}
.welcome_block .header .nav_menu ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: clamp(15px, 2.4vw, 60px);
  list-style: none;
  width: 100%;
  font-weight: 600;
  font-size: clamp(12px, 0.83vw, 28px);
  font-family: "Montserrat", sans-serif;
}
.welcome_block .header .nav_menu li {
  color: #F8F7F2;
  cursor: pointer;
  white-space: nowrap;
}
.welcome_block .header .nav_menu li a {
  color: #fff;
  transition: color 0.3s ease;
}
.welcome_block .header .nav_menu li a:hover {
  color: #5a6cd7;
}
.welcome_block .header .nav_menu button {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Peace Sans";
  font-size: clamp(12px, 0.83vw, 28px);
  line-height: clamp(11px, 0.8vw, 26px);
  white-space: nowrap;
  width: auto;
  height: 4.5vh;
  padding: 1.45vh 0.83vw;
  margin-left: 14.4vw;
  background-color: #fff;
  color: #465299;
  border-radius: 2px;
  transition: all 0.3s ease;
}
.welcome_block .header .nav_menu button a {
  color: #465299;
}
.welcome_block .header .nav_menu button:hover {
  background-color: #f6f6fc;
  color: #5a6cd7;
}
.welcome_block .header .nav_menu button:hover a {
  color: #5a6cd7;
}
@media (max-width: 1264px) {
  .welcome_block .header ul button {
    margin-left: 4.4vw !important;
  }
}
@media (max-width: 980px) {
  .welcome_block .header .logo {
    width: clamp(150px, 51vw, 500px);
    margin-left: 8.35vw;
  }
  .welcome_block .header .logo span {
    font-size: clamp(13px, 4.25vw, 36px);
  }
  .welcome_block .header .nav_menu {
    display: flex;
    position: fixed;
    flex-direction: column;
    height: 47.8vh;
    width: 100vw;
    padding-top: 16%;
    padding-bottom: 9%;
    margin: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    overflow-y: auto;
    background-color: #5FC9EE;
    animation: burgerAnimation 0.5s;
    transition: top 0.6s ease-in-out, opacity 0.2s ease-in-out, transform 0.6s ease-in-out;
  }
  .welcome_block .header .nav_menu ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: clamp(10px, 2.6vh, 35px);
    font-size: clamp(14px, 3.7vw, 28px);
  }
  .welcome_block .header .nav_menu ul button {
    width: auto;
    height: auto;
    padding: 14px 16px;
    font-size: clamp(15px, 3.7vw, 25px);
    margin-left: 0;
    margin-right: 0;
    margin-top: clamp(15px, 0.9vh, 45px);
  }
  .welcome_block .header .nav_menu.open {
    top: 0;
    opacity: 1;
    transform: translateY(0);
  }
  .welcome_block .header .nav_menu.close {
    top: -100%;
    transform: translateY(100%);
    opacity: 0;
  }
  @keyframes burgerAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .welcome_block .header .burger_btn {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 8.3vw;
    z-index: 51;
    width: 37.5px;
    height: 27px;
    cursor: pointer;
  }
  .welcome_block .header .burger_btn span {
    height: 4px;
    width: 100%;
    transform: scale(1);
    background-color: #fff;
  }
  .welcome_block .header .burger_btn:before {
    content: "";
    position: absolute;
    top: 0;
    width: 37.5px;
    background-color: #fff;
    height: 4px;
    transition: all 0.3s ease 0s;
    top: 0;
  }
  .welcome_block .header .burger_btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 37.5px;
    background-color: #fff;
    height: 4px;
    transition: all 0.3s ease 0s;
    bottom: 0;
  }
  .welcome_block .header .burger_btn.active {
    position: fixed;
    top: 2.5%;
    left: 64vw;
  }
  .welcome_block .header .burger_btn.active span {
    transform: scale(0);
  }
  .welcome_block .header .burger_btn.active:before {
    top: 50%;
    transform: rotate(-45deg) translate(0, -50%);
  }
  .welcome_block .header .burger_btn.active:after {
    bottom: 50%;
    transform: rotate(45deg) translate(0, 50%);
  }
}
.welcome_block .main {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  flex-grow: 1;
  padding-top: 12.5vh;
  padding-left: 8.85vw;
  padding-right: 8.85vw;
  gap: clamp(80px, 5.7vw, 200px);
}
.welcome_block .main .monologue_block {
  position: relative;
  display: block;
  padding-bottom: 24.48vh;
  padding-top: 12.5vh;
}
.welcome_block .main .monologue_block .rectangle {
  position: relative;
  width: clamp(280px, 37.3vw, 850px);
  height: 100%;
  z-index: 3;
  background-color: #fff;
  border-radius: 40px;
}
.welcome_block .main .monologue_block .rectangle .monologue_text {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 7vh 1.6vw;
  gap: 0.2em;
}
.welcome_block .main .monologue_block .rectangle .monologue_text .name {
  font-family: "Airfool";
  color: #465299;
  font-size: clamp(25px, 3.3vw, 70px);
  text-shadow: 1px 0px 0px #fff, 0px 1px 0px #fff, -1px 0px 0px #fff, 0px -1px 0px #fff;
  line-height: clamp(32px, 4.2vh, 46px);
}
.welcome_block .main .monologue_block .rectangle .monologue_text .school {
  font-family: "Peace Sans";
  color: #465299;
  font-size: clamp(15px, 1.7vw, 42px);
}
.welcome_block .main .monologue_block .rectangle .monologue_text .introduction {
  font-family: "Montserrat";
  color: #1B3D63;
  font-weight: 400;
  font-size: clamp(11px, 1.25vw, 34px);
}
.welcome_block .main .monologue_block .rectangle .monologue_text .try_button {
  z-index: 4;
  margin-top: 1.4em;
  font-family: "Airfool";
  white-space: nowrap;
  background-color: #C34949;
  text-shadow: 1px 0px 0px #582523, 0px 1px 0px #582523, -1px 0px 0px #582523, 0px -1px 0px #582523;
  font-size: clamp(12px, 1.35vw, 36px);
  color: #fff;
  border-radius: 30px;
  width: clamp(200px, 24.6vw, 830px);
  height: auto;
  padding: clamp(11px, 2.4vh, 25px) 1.5vw;
  transition: background-color 0.3s ease;
}
.welcome_block .main .monologue_block .rectangle .monologue_text .try_button a {
  color: #fff;
}
.welcome_block .main .monologue_block .rectangle .monologue_text .try_button:hover {
  background-color: rgba(209, 37, 37, 0.9921568627);
}
.welcome_block .main .monologue_block .rectangle .triangle {
  position: absolute;
  bottom: -13.4vh;
  right: 5.3vw;
  width: 0;
  height: 0;
  border-top: 15vh solid #fff;
  border-left: 10.4vw solid transparent;
  border-bottom: 0;
  border-right: 0;
}
.welcome_block .main .famaly_block {
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  align-items: flex-end;
  width: 100%;
  height: 78.6vh;
  z-index: 1;
}
.welcome_block .main .famaly_block .family_img {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, 5%);
  width: 100%;
  height: auto;
  z-index: 3;
}
.welcome_block .main .famaly_block .paper_plain_img_phone {
  display: none;
}
.welcome_block .main .paper_plain_img {
  position: absolute;
  width: 15%;
  height: auto;
  top: calc(12.5vh - 2%);
  left: 55.5%;
  z-index: 2;
  transition: left 2s ease-out, top 2s ease-out;
}
.welcome_block .main .paper_plain_img.fly_away {
  top: 70vh;
  left: -30vw;
  z-index: 100;
}
@media screen and (orientation: portrait) {
  .welcome_block .main {
    flex-direction: column;
    align-items: center;
    padding-top: clamp(35px, 4.8vh, 55px);
    padding-left: 0;
    padding-right: 0;
  }
  .welcome_block .main .monologue_block {
    padding-left: 8.85vw;
    padding-right: 8.85vw;
    padding-top: 0;
    padding-bottom: 0;
    height: clamp(190px, 21.8vh, 260px);
  }
  .welcome_block .main .monologue_block .rectangle {
    width: clamp(240px, 83.95vw, 600px);
  }
  .welcome_block .main .monologue_block .rectangle .monologue_text {
    padding: 3.5vh clamp(20px, 6.5vw, 40px);
  }
  .welcome_block .main .monologue_block .rectangle .monologue_text .name {
    font-size: clamp(20px, 7vw, 46px);
    line-height: clamp(22px, 2.8vh, 32px);
  }
  .welcome_block .main .monologue_block .rectangle .monologue_text .school {
    font-size: clamp(12px, 3.6vw, 26px);
    line-height: clamp(14px, 2.9vh, 28px);
  }
  .welcome_block .main .monologue_block .rectangle .monologue_text .introduction {
    font-size: clamp(10px, 2.7vw, 18px);
    line-height: clamp(12px, 1rem, 26px);
  }
  .welcome_block .main .monologue_block .rectangle .monologue_text .try_button {
    font-size: clamp(12px, 2vw, 36px);
    width: clamp(200px, 35vw, 600px);
    padding: clamp(11px, 1vh, 25px) 1.5vw;
  }
  .welcome_block .main .monologue_block .rectangle .triangle {
    border-top: 7.7vh solid #fff;
    border-left: clamp(80px, 23vw, 170px) solid transparent;
    right: clamp(20px, 12vw, 80px);
    bottom: -6.4vh;
  }
  .welcome_block .main .famaly_block {
    height: 100%;
  }
  .welcome_block .main .famaly_block .family_img {
    width: 82.3vw;
    left: 8.85vw;
    right: 8.85vw;
  }
  .welcome_block .main .famaly_block .paper_plain_img_phone {
    position: absolute;
    width: 25%;
    height: auto;
    z-index: 3;
    top: 0;
    left: 20%;
    transition: left 1.5s ease-out, top 1.5s ease;
  }
  .welcome_block .main .famaly_block .paper_plain_img_phone.fly_away {
    top: 30vh;
    left: -30vw;
    z-index: 100;
  }
  .welcome_block .main .paper_plain_img {
    display: none;
    width: 20%;
    top: 0;
    left: 25%;
  }
}
@media (aspect-ratio: 5/4), (aspect-ratio: 4/3) {
  .welcome_block .main .monologue_block {
    padding-bottom: 30.48vh;
  }
  .welcome_block .main .monologue_block .rectangle .monologue_text {
    padding: 5vh 1.6vw;
  }
  .welcome_block .main .monologue_block .rectangle .triangle {
    bottom: -8.4vh;
    border-top: 10vh solid #fff;
  }
  .welcome_block .main .famaly_block .family_img {
    transform: translate(0, 6vh);
    height: 100%;
  }
}/*# sourceMappingURL=welcome.css.map */